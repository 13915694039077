function roundup(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

export function calculateurBoisDePaillage(input, prix) {
  console.log("input");
  var cout, surface, poids;
  // var hectaresRadio = jQuery(".simulateur_bois #hectares")[0].checked;
  // var m2Radio = jQuery(".simulateur_bois #m2")[0].checked;
  var surfaceInput = jQuery(".simulateur_bois #surface");
  var kgInput = jQuery(".simulateur_bois #poids");
  var tonnesInput = jQuery(".simulateur_bois #tonnes");
  var m3Input = jQuery(".simulateur_bois #m3");
  var prixTotal = jQuery(".simulateur_bois #prix_total");

  var m2_return = 0;
  var ha_return = 0;
  var kg_return = 0;
  var tonne_return = 0;
  var volume_return = 0;
  var surf_coef = 1;
  var surface_return = 0;

  m2_return = surfaceInput.val();
  kg_return = m2_return * 12;
  kgInput.val(kg_return);
  tonne_return = kg_return / 1000;
  tonnesInput.val(tonne_return);
  volume_return = kg_return / 150;
  m3Input.val(volume_return);

  // // Calcul en fonction du tonnage
  // if (tonne_return < 2) {
  //   var prix_tonne = prix[0];
  // }
  // if (tonne_return >= 2 && tonne_return < 10) {
  //   var prix_tonne = prix[1];
  // }
  // if (tonne_return >= 10) {
  //   var prix_tonne = prix[2];
  // }
  // Calcul en fonction du tonnage
  if (tonne_return < 2) {
    var prix_tonne = prix[0];
  }
  if (tonne_return >= 2) {
    var prix_tonne = prix[1];
  }

  console.log(prix_tonne);

  // Calcul du Prix
  var prix_total = prix_tonne * tonne_return;
  prixTotal.val(prix_total.toFixed(2));
}

export function calculateurCompost(input, container, prix) {
  console.log(input);
  var cout, poids, surface;
  var surfaceInput = jQuery(container + " #surface");
  var kgInput = jQuery(container + " #poids");
  var tonnesInput = jQuery(container + " #tonnes");
  var prixTotal = jQuery(container + " #prix_total");

  var m2_return = 0;
  var ha_return = 0;
  var kg_return = 0;
  var tonne_return = 0;
  var surf_coef = 1;
  var surface_return = 0;

  // Demande par surface
  m2_return = surfaceInput.val();
  kg_return = m2_return * 2;
  kgInput.val(kg_return);
  tonne_return = kg_return / 1000;
  tonnesInput.val(tonne_return);

  // Calcul en fonction du tonnage
  // if (tonne_return < 2) {
  //   var prix_tonne = prix[0];
  // }
  // if (tonne_return >= 2 && tonne_return < 10) {
  //   var prix_tonne = prix[1];
  // }
  // if (tonne_return >= 10) {
  //   var prix_tonne = prix[2];
  // }
  // Calcul en fonction du tonnage
  if (tonne_return < 2) {
    var prix_tonne = prix[0];
  }
  if (tonne_return >= 2) {
    var prix_tonne = prix[2];
  }

  console.log(prix_tonne);

  // Calcul du Prix
  var prix_total = prix_tonne * tonne_return;
  prixTotal.val(prix_total.toFixed(2));
}
export function calculateurMateriaux(input, prix) {
  var cout, poids;

  console.log(input);

  var tonnesInput = jQuery(".simulateur_materieux #surface");
  var isVenant = jQuery(".simulateur_materieux #venant")[0].checked;
  var isRemblai = jQuery(".simulateur_materieux #remblai")[0].checked;
  var kgInput = jQuery(".simulateur_materieux #poids");
  var prixTotal = jQuery(".simulateur_materieux #prix_total");

  var kg_return = 0;
  var tonne_return = 0;
  var prix_tonne;

  // Demande par tonne
  if (input === "surface") {
    tonne_return = tonnesInput.val();

    kg_return = tonne_return * 1000;
    kgInput.val(kg_return);
  }

  // Demande par kg
  if (input == "poids") {
    kg_return = kgInput.val();
    tonne_return = kg_return / 1000;
    tonnesInput.val(tonne_return);
  }

  // Calcul en fonction du tonnage
  if (isRemblai) {
    tonne_return = tonnesInput.val();
    kg_return = kgInput.val();
    prix_tonne = 9.6;
  }

  if (isVenant) {
    tonne_return = tonnesInput.val();
    kg_return = kgInput.val();
    if (tonne_return < 500) {
      prix_tonne = prix[0];
    } else if (tonne_return >= 500 && tonne_return < 1000) {
      prix_tonne = prix[1];
    } else {
      prix_tonne = prix[2];
    }
  }
  console.log(prix_tonne);

  // Calcul du Prix
  var prix_total = prix_tonne * tonne_return;
  prixTotal.val(prix_total.toFixed(2));
}
export function calculateurTerre(input, prix) {
  console.log("Terre");
  console.log(input);
  console.log(prix);
  var cout, poids, volume;
  var m3Input = jQuery(".simulateur_terre #volume").val() || 0;
  var kgInput = jQuery(".simulateur_terre #poids");
  var tonnesInput = jQuery(".simulateur_terre #tonnes");
  var prixTotal = jQuery(".simulateur_terre #prix_total");

  var m2_return = 0;
  var kg_return = 0;
  var tonne_return = 0;
  var m3_return = 0;
  var surface_return = 0;

  console.log("input = " + input);
  if (input == "volume" || input == "tonnes" || input == "poids") {
    // désactiver les dimensions
    jQuery(".simulateur_terre #hauteur").val(0);
    jQuery(".simulateur_terre #largeur").val(0);
    jQuery(".simulateur_terre #longeur").val(0);
  }

  // Demande par m3
  if (input === "volume") {
    console.log(input);
    m3_return = jQuery(".simulateur_terre #volume").val();

    console.log(m3_return);
    kg_return = m3_return * 1250;
    kgInput.val(kg_return);

    tonne_return = kg_return / 1000;
    tonnesInput.val(tonne_return);
  }

  // Demande par tonne
  if (input === "tonnes") {
    tonne_return = tonnesInput.val();

    m3_return = (tonne_return * 1000) / 1250;
    jQuery(".simulateur_terre #volume").val(m3_return);

    kg_return = tonne_return * 1000;
    kgInput.val(kg_return);
  }

  // Demande par kg
  if (input == "poids") {
    kg_return = kgInput.val();

    tonne_return = kg_return / 1000;
    tonnesInput.val(tonne_return);

    m3_return = (tonne_return * 1000) / 1250;
    jQuery(".simulateur_terre #volume").val(m3_return);
  }

  // Demande par dimensions
  if (input == "largeur" || input == "longeur" || input == "hauteur") {
    // désactiver Volume, kg et tonnes
    jQuery(".simulateur_terre #volume").val(0);
    jQuery(".simulateur_terre #poids").val(0);
    jQuery(".simulateur_terre #tonnes").val(0);

    // On attend que les 3 champs permettant de calculer le volume soit rempli

    var largeur = jQuery(".simulateur_terre #largeur").val();
    var longueur = jQuery(".simulateur_terre #longeur").val();
    var hauteur = jQuery(".simulateur_terre #hauteur").val();

    console.log("l = " + largeur + "*L=" + longueur + "*H=" + hauteur);

    if (largeur > 0 && longueur > 0 && hauteur > 0) {
      m3_return = largeur * longueur * hauteur;
      console.log("volume=" + m3_return);

      //jQuery(".simulateur_terre #volume").val(m3_return.toFixed(2));
    }

    jQuery(".simulateur_terre #volume").val(m3_return);

    kg_return = m3_return * 1250;
    kgInput.val(kg_return);

    tonne_return = kg_return / 1000;
    tonnesInput.val(tonne_return);
  }

  // Calcul en fonction du tonnage
  var prix_tonne = prix;
  console.log(prix_tonne);

  // Calcul du Prix
  var prix_total = prix_tonne * tonne_return;
  prixTotal.val(prix_total.toFixed(2));
}
