// Styles
// import '../sass/single.scss';
import {
  calculateurBoisDePaillage,
  calculateurCompost,
  calculateurMateriaux,
  calculateurTerre,
} from "./utils/calculateurs";

jQuery(function () {
  let timer = null;
  var prix_paillage = [];
  var prix_croissance = [];
  var prix_structurant = [];
  var prix_venant = [];
  var prix_terre = jQuery(".prix_terre").data("prix");
  jQuery(".prix_paillage").each(function () {
    var prix_tonnes = jQuery(this).data("prix");
    prix_paillage.push(prix_tonnes);
  });
  jQuery(".prix_croissance").each(function () {
    var prix_tonnes = jQuery(this).data("prix");
    prix_croissance.push(prix_tonnes);
  });
  jQuery(".prix_structurant").each(function () {
    var prix_tonnes = jQuery(this).data("prix");
    prix_structurant.push(prix_tonnes);
  });
  jQuery(".prix_venant").each(function () {
    var prix_tonnes = jQuery(this).data("prix");
    prix_venant.push(prix_tonnes);
  });
  // console.log(prix_paillage);
  // console.log(prix_croissance);
  // console.log(prix_structurant);
  // console.log(prix_venant);
  jQuery(
    ".simulateur_bois #surface, .simulateur_bois #poids, .simulateur_bois #tonnes"
  ).keyup(function () {
    const input = jQuery(this).attr("id");
    clearTimeout(timer);
    timer = setTimeout(calculateurBoisDePaillage, 500, input, prix_paillage);
  });
  jQuery(".simulateur_bois input[type=radio]").on("click", function () {
    const input = jQuery(this).attr("id");
    clearTimeout(timer);
    timer = setTimeout(calculateurBoisDePaillage, 500, input);
  });
  jQuery(
    ".simulateur_compost_structurant #surface, .simulateur_compost_structurant #poids, .simulateur_compost_structurant #tonnes "
  ).keyup(function () {
    const input = jQuery(this).attr("id");
    clearTimeout(timer);
    timer = setTimeout(
      calculateurCompost,
      500,
      input,
      ".simulateur_compost_structurant ",
      prix_structurant
    );
  });
  jQuery(".simulateur_compost_structurant input[type=radio]").on(
    "click",
    function () {
      const input = jQuery(this).attr("id");
      clearTimeout(timer);
      timer = setTimeout(
        calculateurCompost,
        500,
        input,
        ".simulateur_compost_structurant ",
        prix_structurant
      );
    }
  );
  jQuery(
    ".simulateur_compost_croissance #surface, .simulateur_compost_croissance #poids, .simulateur_compost_croissance #tonnes "
  ).keyup(function () {
    const input = jQuery(this).attr("id");
    clearTimeout(timer);
    timer = setTimeout(
      calculateurCompost,
      500,
      input,
      ".simulateur_compost_croissance ",
      prix_croissance
    );
  });
  jQuery(".simulateur_compost_croissance input[type=radio]").on(
    "click",
    function () {
      const input = jQuery(this).attr("id");
      clearTimeout(timer);
      timer = setTimeout(
        calculateurCompost,
        500,
        input,
        ".simulateur_compost_croissance ",
        prix_croissance
      );
    }
  );
  jQuery(".simulateur_materieux #surface, .simulateur_materieux #poids").keyup(
    function () {
      const input = jQuery(this).attr("id");
      clearTimeout(timer);
      timer = setTimeout(calculateurMateriaux, 500, input, prix_venant);
    }
  );
  jQuery(".simulateur_materieux input[type=radio]").on("click", function () {
    const input = jQuery(this).attr("id");
    clearTimeout(timer);
    timer = setTimeout(calculateurMateriaux, 500, input);
  });
  jQuery(
    ".simulateur_terre #volume, .simulateur_terre #largeur, .simulateur_terre #longeur, .simulateur_terre #hauteur, .simulateur_terre #poids, .simulateur_terre #tonnes"
  ).keyup(function () {
    const input = jQuery(this).attr("id");
    clearTimeout(timer);
    timer = setTimeout(calculateurTerre, 500, input, prix_terre);
  });
});
